/* Typography */
h1 {
    font-weight: 600;
    margin: 0;
    font-size: 24px;
}

h2 {
    font-size: 20px;
    font-weight: normal;
    margin: 0;
}

.card {
    background-color: #ffffff;
    padding-top: 1em;
    margin-bottom: 16px;
    @include border-radius($borderRadius);

    &.card-w-title {
        padding-bottom: 2em;
    }

    h1 {
        margin: 1em 0 .5em 0;
        border-bottom: 1px solid #d5d5d5;
        padding: .1em;
        font-size: 24px;

        &:first-child {
            margin: 0 0 .5em 0;
        }
    }
}

.colored-label {
    text-align: center;
    display: inline-block;
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;
    @media (max-width: 1500px) {
        font-size: 11.5px !important;
    }
    &.green {
        color: #20a144;
        background-color: #ecf6ef;
    }
    &.light-green {
        color: #31d8a4;
        background-color: #ecf6ef;
    }
    &.orange {
        color: #e56e19;
        background-color: #fff7f2;
    }
    &.red {
        color: #fc453b;
        background-color: #ffebea;
    }
    &.blue {
        color: #007bff;
        background-color: #e7f3ff;
    }
    &.yellow {
        color: #ff9f15;
        background-color: #fffae8;
    }

    &.fixed-length {
        min-width: 96px !important;
    }
}

.no-margin {
    margin: 0px !important;
}

.no-padding {
    padding: 0px !important;
}

.red {
    color: red !important;
}

.black {
    color: black !important;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    color: rgb(74, 74, 233);
    cursor: pointer;
    text-decoration: underline;
}

.quebrarLinhaTexto {
    word-wrap: break-word;
}