.aptidao-container {
  background-color: #FFFFFF;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  border-radius: 30px 30px 30px 30px;
}

.form-divider {
	width: 100% !important;
	border-bottom: 1px solid #e8e9eb !important;
	margin-bottom: 20px !important;
}