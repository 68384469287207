.textinput{
    margin-top: 10px;

    .required{
        border: 1px red solid;
        border-radius: 5px;
    }

    .label{
        color: red
    }

}