@import '../../../layout/variables';
@import '../../../layout/animations';

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown {
  z-index: 9999;
  position: absolute;
  margin-top: 35px;
  margin-bottom: 35px;
  max-width: 170px;
  min-width: 100px;
  background-color: $bg;
  border-radius: 16px;
  padding: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 0.4em $bodyLight;
  overflow: hidden;
  transform-origin: bottom center;
  animation: growDown 150ms ease-in-out forwards;

  @media (max-width: 768px) {
		margin-top: 55px;
	}
}
.top {
  margin-top: -345px;
  @media (max-width: 768px) {
    margin-top: -300px;
  }
}

.dropdown-button {
  width: 100%;
  box-shadow: none !important;

  &:hover {
    box-shadow: none !important;
  }

  @media (max-width: 768px) {
		border-radius: 32px !important;
	}
}

.only-icon {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
  }

  .pi {
    color: black;
  }
}

.dropdown-item {
  padding: 3px;
  overflow-wrap: break-word;
  &:hover {
    cursor: pointer;
  }
}