.layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    /*padding: 1em;*/
    padding-bottom: 2px;
    color: #ffffff;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 20px;


    img {
        vertical-align: middle;
    }

    .footer-text {
        vertical-align: middle;
        text-align: center;
    }

}

.layout-footer-login {
    @include transition(margin-left $transitionDuration);
    padding-bottom: 2px;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 60px;
    background-color: #353C46;
    @media (max-width: 1500px) {
        height: 40px;
    }

    .footer-text-login {
        line-height: 60px;
        text-align: center;
        color: #ffffff;
        @media (max-width: 768px) {
            font-size: 1rem;
        }
        @media (max-width: 1500px) {
            line-height: 40px;
            font-size: 0.8rem;
        }
    }
}