@import '../../layout/sass/mixins';

.table-preferences-container {
  width: 65%;
  max-width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  padding: 4px;

  @include for(medium-resolution) {
    padding: 0px;
  }

  .table-preferences-switch {
    @include for(medium-resolution) {
      margin-top: 5px;
    }

    span {
      @include for(medium-resolution) {
        font-size: 5px;
        height: 24px;
      }

      &::before {
        @include for(medium-resolution) {
          width: 1rem;
          height: 1rem;
          left: 0.45rem;
          margin-top: -0.5rem;
        }
      }
    }
  }
}

.label-configuracao-modulos {
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: large;
  cursor: pointer;

  width: 130px;

  &:hover {
    color: rgb(66, 66, 66);
  }
}

.label-nome-empresa {
  color: black;
  font-weight: 500;
  font-size: large;
  width: 35%;
  max-width: 35%;

  cursor: auto !important;

  &:hover {
    color: black !important;
  }
}

.configuracao-modulos-row {
  margin-bottom: 20px;
}

.container-label-configuracao-modulos {
  width: 65%;
  max-width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.configuracao-modulos-row-nome-empresa {
  width: 35%;
  max-width: 35%;
}

.input-switch-container {
  display: flex;
  justify-content: center;

  width: 130px;
  max-width: 130px;
}