input.xml-inputfile[type="file"] {
  display: none;
}
.button-file-upload {
  border: 1px solid #ccc;
  display: inline-flex;
  cursor: pointer;
  background-color: #304FFD;
  border-radius: 16px;
  padding: 8px!important;
  padding-left: 15px!important;
  padding-right: 15px!important;
  border-color: #304FFD !important;
  border-bottom: 2px solid #263fca !important;
  color: #ffffff;
  border: 1px solid #007bff;
  font-size: 14px !important;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  
  @media (max-width: 1500px) {
    padding: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    font-size: 12px !important;
  }

  &:hover {
    background-color: #2843e1 !important;
  }

  i {
    color: white;
    line-height: 20px !important;
  }
}