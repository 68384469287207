.p-disabled{
    cursor: text !important;
    pointer-events: auto !important;
  }
  
  .p-dropdown {
    -webkit-user-select: text;
    user-select: text;
  }

// .buttons-container {
//   margin-bottom: 6px;
//   margin-top: 6px;
//   width: 100%;

//   button {
//     margin-right: 12px;
//     float: left;
//   }
// }

// .container-button-Flex {
//   display: flex;
//   align-items: center;
//   justify-items: center;
//   justify-content: space-between;

//   button {
//     margin-left: 0.5em;
//   }
// }

// .container-title {
//   margin-bottom: 10px;
// }

// .panel-container {
//   margin-bottom: 15px;
// }

// .login-page {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   color: #cccccc;

//   .login-body {
//     height: 80vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

//     @media (min-width: 768px) {
//       height: 80vh;
//     }

//     @media (min-width: 950px) {
//       flex-direction: row;
//       height: 80vh;
//     }

//     .login-logo {
//       width: 310px;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       img {
//         height: 3em;
//       }

//       h1 {
//         padding-left: 0.3em;
//         font-size: 2em;
//         font-weight: 700;
//         color: rgb(63, 63, 63);
//       }

//       @media (min-width: 360px) {
//         width: 400px;

//         img {
//           height: 4em;
//         }

//         h1 {
//           font-size: 3em;
//         }
//       }

//       @media (min-width: 769px) {
//         width: 75%;

//         img {
//           height: 6em;
//         }

//         h1 {
//           font-size: 4em;
//         }
//       }

//       @media (min-width: 950px) {
//         width: 75%;

//         img {
//           height: 8em;
//         }

//         h1 {
//           font-size: 5em;
//         }
//       }

//       @media (min-width: 1300px) {
//         width: 75%;

//         img {
//           height: 10em;
//         }

//         h1 {
//           font-size: 6em;
//         }
//       }

//     }

//     .login-form {
//       width: 100%;

//       @media (min-width: 950px) {
//         width: 25%;
//       }

//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-direction: column;
//       padding: 30px;

//     }
//   }
// }

// .loading-container {
//   margin: 0;
//   padding: 0;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: 9999;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.8);
// }

// .layout-logo {
//   img {
//     height: 4em;
//   }
// }

// .layout-topbar,
// .layout-footer {
//   background: #2aa78f;
// }

// .login-welcome {
//   font-size: 1.5em;
//   color: #2aa78f;
//   text-align: left;

//   margin-bottom: 0.5em;

//   @media (min-width: 360px) {
//     font-size: 2em;
//   }
// }

// .login-link {
//   background-color: rgba($color: #000, $alpha: 0.0) !important;
//   color: #f8921f !important;
//   border: none !important;
//   outline: none !important;
//   text-align: left !important;

//   span {
//     padding: 0 !important;
//   }
// }

// .login-button {
//   background-color: #2aa78f !important;
//   border: #2aa78f !important;
// }

// .list-selection {
//   background-color: #fff;

//   &:nth-child(even) {
//     background-color: rgba(204, 204, 204, 0.288);
//   }

//   div {
//     padding: 5px;
//   }

//   &:hover {
//     background-color: rgba(204, 204, 204, 0.1);
//     cursor: pointer;
//   }

//   &.active {
//     background-color: #2ea78fb0;
//     color: #fff;
//   }
// }

// .layout-profile-link {
//   padding: 12px;
// }

// .layout-registro-panel {
//   padding: 3% 10%;
// }

// /* Corrige barra de rolagem do menu lateral */
// .p-scrollpanel-content {
//   padding: 0 !important;
//   overflow: scroll;
// }

// .register-logo {
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   h1 {
//     font-weight: bold;
//     font-size: 5em;
//     padding: 10px;
//   }

//   img {
//     padding: 10px;
//     height: 150px;
//   }

//   @media (max-width: 765px) {
//     white-space: nowrap;

//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     img {
//       height: 5em;
//     }

//     h1 {
//       font-size: 4em;
//     }
//   }

//   @media (max-width: 515px) {
//     white-space: nowrap;

//     img {
//       height: 4em;
//       padding: 5px;
//     }

//     h1 {
//       font-size: 3em;
//       padding: 5px;
//     }
//   }
// }

// .new-account {
//   font-weight: bold;
//   font-size: 1.9em;
//   margin-bottom: 10px;

//   @media (max-width: 515px) {
//     font-size: 1.55em;
//   }
// }

// .input-icon {
//   input {
//      padding-left: 33px;
//   }

//   label {
//     padding-left: 30px;
//   }

//   .no-padding {
//     padding-left: 0px;
//   }
// }

// .input-div {
//   margin-top: 10px;
// }

// .pi-sort-alt {
//   display: none !important;
// }

// @media screen and (max-width: 768px) {
//     .p-datatable.p-datatable-responsive .p-datatable-tbody > .p-datatable-row {
//         &:first-child {
//             border-top: 0px;
//         }
        
//         border-top: 1px solid rgb(202, 202, 202);
//     }

//     .p-datatable-row > td:first-child{
//         margin-top: 12px;
//     }

//     .p-datatable-row > td:last-child{
//         margin-bottom: 12px;
//     }

//     .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
//     .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
//         display: none !important;
//     }

//     .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
//         text-align: left !important;
//         display: block;
//         width: 100% !important;
//         float: left;
//         clear: left;
//         border: 0 none;
//         margin-left: 5px;
//     }

//     .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
//         padding: .4rem;
//         min-width: 35%;
//         max-width: 35%;
//         display: block;
//         margin: -.4em 1em -.4em -.4rem;
//         font-weight: bold;
//         float: left;
//         text-align: left;
//     }

//     .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
//         border-bottom: 1px solid var(--surface-d);
//     }
// }

// .checkbox-container {
//   display: flex;
//   align-items: center;
//   margin-top: 13px;
// }

// @media (max-width: 768px) {
//   .button-impressao {
//     width: 100% !important;
//     margin-left: 0px !important;
//     margin-right: 0px !important;
//     margin-top: 6px;
//     margin-bottom: 7px;
//   }
// }