.layout-sidebar {
    &::-webkit-scrollbar {
      width: 0px !important;
    }

    position: fixed;
    width: 274px;
    height: 100%;
    z-index: 999;
    overflow-y: auto;
    @include transition(left $transitionDuration);
    // @include shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));
    border-right: 1px solid #E8E9EB;

    .layout-logo {
        text-align: center;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
            color: black !important;
        }

        .logo-title {
            font-weight: 500;
            font-size: 18px;
        }
        img{
            object-fit: contain;
            width: 100%;  
        }
    }

    .menuitem-badge {
        display: inline-block;
        margin-left: 6px;
        font-size: 10px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: $menuitemBadgeColor !important;
        background-color: $topbarItemBadgeBgColor;
        @include border-radius(40%);
    }
}