@import '../variables';

.topbar-alert {
    margin-bottom: 25px;
    position: relative;
    padding: 20px;
    background-color: $yellow;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    span {
        color: black;
    }

    .alert-divider {
        &.p-divider.p-divider-horizontal:before {
            border-top: 1px solid black !important;
        }
    }
}


.layout-main {
    @include transition(margin-left $transitionDuration);
    padding: 65px 0px 16px 0px;

    @media (max-width: 768px) {
        padding: 65px 0px 70px 0px;
    }
}

.layout-content {
    padding: 0px 30px;

    @media (max-width: 768px) {
        padding: 0px 14px;
    }
}