@import "../_variables";

.warning-certificado {
  font-size: 2rem;

  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.buttons-container {
  margin-bottom: 6px;
  margin-top: 6px;
  width: 100%;

  button {
    margin-right: 12px;
    float: left;
  }

  @media screen and (max-width: '640px') {
    display: block;

    button {
      margin: 0.5em 0;
      width: 100%;
      display: block;
    }
  }
}

.container-button-Flex {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;

  button {
    margin-left: 0.5em;
  }
}

.container-title {
  margin-bottom: 10px;
}

.panel-container {
  margin-bottom: 15px;
}

.login-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #cccccc;

  .login-body {
    height: 100vh;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    @media (min-width: 950px) {
      flex-direction: row;
    }

    .login-first-area {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
    }

    .login-logo {
      height: 100%;

      .logo-body {
        .logo-welcome {
          color: #353C46;
          font-size: 2.4rem;
          font-weight: 700;
          text-align: center;

          @media (max-width: 1500px) {
            font-size: 2rem;
          }

          &.sub-text {
            font-size: 1.3rem !important;

            @media (max-width: 1500px) {
              font-size: 1.1rem !important;
            }

            font-weight: 600 !important;
            text-decoration: underline !important;
            color: #686C72;
            margin-bottom: 50px;
          }
        }

        .border-1 {
          border-width: 0px !important;
          border-style: solid !important;
        }

        .p-carousel-next {
          display: none;
        }

        .p-carousel-prev {
          display: none;
        }

        .p-carousel-indicators {
          display: none;
        }

        .carousel-name {
          color: #263238;
        }

        .homem-na-poltrona {
          height: 284px !important;

          @media (max-width: 1500px) {
            height: 240px !important;
          }
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 6px solid #43FFC41F;
        height: 80%;
      }

      .logo {
        margin-left: 50px;
        margin-top: 40px;
      }
    }

    .login-form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 15vh 150px;


      @media (max-width: 768px) {
        padding: 40px;
        padding-top: 0px;
        height: 100%;
      }

      .icone-login {
        @media (max-width: 768px) {
          margin-top: 60px;
          margin-bottom: 0px !important;
          height: 10%;
        }
      }
    }
  }
}


.loading-container {
  margin: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.layout-topbar {
  background: #FFFFFF;
}

.login-welcome {
  font-size: 1.5rem;
  color: #353C46;
  font-weight: 700;

  @media (max-width: 1500px) {
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.login-small {
  color: #353C46;
  font-size: 0.9rem;
}

.login-small-link {
  color: #0069FF;
  font-size: 0.9rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.login-smaller-link {
  color: #0069FF;
  font-size: 0.75rem;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.spinner-text {
  z-index: 9999999 !important;
  color: black !important;
  font-size: 1rem;
  background-color: #808080;
  border-radius: 15px;
  padding: 5px 15px;
}

.list-selection {
  background-color: #fff;

  div {
    padding: 5px;
  }

  border-bottom: 1px solid $bodyLight;

  &.first {
    border-top: 1px solid $bodyLight;
  }

  &:hover {
    background-color: #8080802a;
    cursor: pointer;
  }
}

.layout-profile-link {
  padding: 20px;
}

label.observacao {
  display: block;
  color: grey;
  padding-left: 5px;
  font-size: 11px;
}

.label-over {
  position: absolute;
  color: gray;
  right: 15px;

  @media (min-width: 500px) {
    bottom: 8px;
  }
}

.label-over-cadastro {
  position: absolute !important;
  color: gray !important;
  right: 60px !important;
  bottom: 10px !important;

  @media (min-width: 500px) {
    bottom: 26px;
  }
}

.label-over-vehicle {
  position: absolute;
  color: gray;
  left: 98px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 98px);

  @media (min-width: 500px) {
    bottom: 9px;
  }
}

.label-over-vehicle-cadastro {
  position: relative;
  color: gray;
  left: 98px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 98px);
  bottom: 35px;
}

$modal-sizes: (
  20: 20vw,
  25: 25vw,
  30: 30vw,
  35: 35vw,
  40: 40vw,
  45: 45vw,
  50: 50vw,
  55: 55vw,
  60: 60vw,
  65: 65vw,
  70: 70vw,
  75: 75vw
);

@each $size-width,
$width in $modal-sizes {
  .modal-vw-#{$size-width} {
    width: $width;

    @media (max-width: 1268px) {
      width: 68vw;
    }

    @media (max-width: 992px) {
      width: 90vw;
    }

    @media (max-width: 768px) {
      width: 96vw;
    }
  }
}

.disabled-input {
  input:disabled {
    opacity: 0.81;
  }

  select:disabled {
    opacity: 0.81;
  }
}

.negociacao-buttons {
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    button {
      margin-bottom: 10px !important;
      width: 100% !important;
    }
  }
}

.neg-restante-row {
  padding: 0 0 0.4em 0;
  border-top: solid 1px #c8c8c8;

  &:first-child {
    border: 0px;
  }

  &:last-child {
    border-top: solid 1px #a9a9a9;
  }
}

.negociacao-info {
  font-size: 1.1em;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
}

.link-integracao {
  padding-left: 20px;
  padding-bottom: 20px;
  margin: 0;

  background-color: #2ea78fb0;
  color: white;
  cursor: pointer;
}

.p-autocomplete-input {
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 14px;

  &.margin-top {
    margin-top: 35px !important;

    @media (max-width: 1500px) {
      margin-top: 24px !important;
    }
  }
}

.cadastrar-register-modal {
  text-decoration-line: underline;
  color: $bodyLight;

}

.cadastrar-register-modal:hover {
  cursor: pointer;
}

.btn-option-container {
  background-color: $menuitemDarkActiveColor !important;
  border-radius: 5px !important;
  width: 245px !important;
  margin-bottom: 5px;
  margin-left: 20px;

  .btn-option {
    border-bottom: transparent !important;
    border-radius: 0px 5px 5px 0px !important;
    margin-left: 5px !important;
    background-color: $menuitemActiveRouteColor !important;
    width: 243px;
    border: none !important;

    &:hover {
      background-color: $menuitemDarkActiveColor !important;
    }
  }
}

.checkbox-documento-modal {
  margin-bottom: 14px !important;
  margin-top: 0px !important;

  label {
    margin-bottom: 14px !important;
  }
}

label {
  margin-bottom: 0px !important;
  color: $bodyLight;
  font-style: normal;
}

.form-header {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    button {
      margin-top: 12px;
      width: 100%;
    }
  }
}

.form-body {
  min-width: 314px;
}

.form-footer {
  padding-top: 2rem;
}

.option-select {
  margin-bottom: 8px;

  .p-button:first-of-type {
    border-right: none !important;
  }

  .p-button {
    background-color: transparent;
    border: 1px solid $bodyLight !important;
    padding: 0px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
    box-shadow: none !important;

    &:hover {
      background-color: #8A909910 !important;
    }

    &.p-highlight {
      background-color: $accent;

      .p-button-label {
        color: white;
      }

      &:hover {
        background-color: $accent !important;
      }
    }

    .p-button-label {
      color: $bodyLight;
    }
  }

  &.config {
    .p-button {
      padding: 10px 15px !important;

      @media (max-width: 1500px) {
        padding: 7px 13px !important;
      }

      .p-button-label {
        font-size: 1rem !important;

        @media (max-width: 1500px) {
          font-size: 0.9rem !important;
        }
      }
    }
  }
}

.p-sortable-column,
.p-selectable-row {
  font-size: 0.9em !important;

  @media (max-width: 1500px) {
    font-size: 0.8em !important;
  }
}

.p-column-title {
  @media (max-width: 1500px) {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 446px) and (max-width: 576px) {

  .p-xsm-1,
  .p-xsm-2,
  .p-xsm-3,
  .p-xsm-4,
  .p-xsm-5,
  .p-xsm-6,
  .p-xsm-7,
  .p-xsm-8,
  .p-xsm-9,
  .p-xsm-10,
  .p-xsm-11,
  .p-xsm-12 {
    flex: 0 0 auto;
    padding: 0.5em;
  }

  .p-xsm-1 {
    width: 8.3333%;
  }

  .p-xsm-2 {
    width: 16.6667%;
  }

  .p-xsm-3 {
    width: 25%;
  }

  .p-xsm-4 {
    width: 33.3333%;
  }

  .p-xsm-5 {
    width: 41.6667%;
  }

  .p-xsm-6 {
    width: 50%;
  }

  .p-xsm-7 {
    width: 58.3333%;
  }

  .p-xsm-8 {
    width: 66.6667%;
  }

  .p-xsm-9 {
    width: 75%;
  }

  .p-xsm-10 {
    width: 83.3333%;
  }

  .p-xsm-11 {
    width: 91.6667%;
  }

  .p-xsm-12 {
    width: 100%;
  }
}

.required::after {
  content: "*";
  color: $darkRed;
}

.p-datatable-emptymessage td {
  padding: 10px !important;
}

.p-rowgroup-header-name {
  display: block;
  font-weight: bold;
  text-align: center;
}

.p-treetable td {
  padding: 5px !important;
}

.p-inputnumber-input {
  max-width: 100% !important;
}

.p-sortable-column .pi-sort-alt {
  display: none !important;
}

.active-route {

  span,
  i {
    color: #2dc797 !important;
  }
}

.separator {
  border-top: 1px solid #dadde0 !important;
  padding-top: 26px !important;
  margin-top: 23px !important;
}

.imagem-container {
  position: relative;
}

.imagem {
  border: 1px solid rgb(214, 214, 214);
  margin: 3px;
  border-radius: 7px;
  display: block;
  width: 130px;
  height: 130px;
  transition: .33s ease;
  object-fit: cover;
}

.excluir-imagem {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 0.8em;
  background-color: rgba(38, 38, 38, 0.96);
  color: rgb(233, 233, 233);
  font-weight: bold;
  padding: 4px;
  border-radius: 100%;
  cursor: pointer;
}

.p-fileupload-files {
  display: flex !important;
}

.p-message-error {
  display: none !important;
}

.p-message-enter-done {
  display: none !important;
}

.p-message-enter-done .p-message-wrapper {
  display: none !important;
}

.p-message-exit-active {
  display: none !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0;
}