@import "../../../layout/variables";

.importar-xml-datalist {
  .p-datatable-thead {
    tr > th{
      &:first-of-type {
        border-top-left-radius: 30px;
      }
      &:last-of-type {
        border-top-right-radius: 30px;
      }
    }
  }
}

.icone-info {
  margin-left: 7px;
  color: $accent;

  &:hover {
    cursor: pointer;
  }

  &.confirmar-entrada {
    vertical-align: middle;
    padding-bottom: 3px;

    @media (max-width: 1500px) {
      padding-bottom: 0px;
    }
  }

  &.veiculosList {
    vertical-align: middle;
    font-size: 25px;

    @media (max-width: 1500px) {
      font-size: 23px;
    }
  }
  
  &.legenda {
    font-size: 1.3rem;

    &-tooltip {
      max-width: fit-content !important;

      .p-tooltip-text {
        font-size: 0.7rem;
      }
    }
  }
}
