.inputfile{
    margin-top: 10px !important;
    padding: 5px !important;

    .required{
        border: 1px red solid;
        border-radius: 5px;
    }

    .label{
        color: red;
    }

    input[type="file"] {
        display: none;
    }

    span {
        @media (max-width: 1500px) {
            font-size: 12px !important;
        }
    }

    // .custom-file-upload {
    //     margin: 0;
    //     color: #fff;
    //     background-color: #007ad9;
    //     border: #007ad9;
    //     font-size: 14px;
    //     transition: background-color 0.2s, box-shadow 0.2s;
    //     border-radius: 3px;
    //     padding: 7px;
    //     cursor: pointer;
    // }
}

.disabled{
    display: none;
}