@import "../_variables";

.page-header {
    h4 {
        padding-left: 15px !important;
        font-weight: 550;
    }
    @media (max-width: 768px) {
        display: block !important;

        h4 {
            width: 100%;
            font-size: 26px;
            margin-top: 15px !important;
            margin-bottom: 15px !important;
        }
        
        .page-header-buttons {
            padding-top: 10px !important;
            padding-bottom: 10px !important;

            button {
                width: 100%;
                margin: 0px !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

.p-error {
  color: #dc3545 !important; 
}

.p-component, .p-dropdown-label {
    font-family: 'Poppins', 'Open Sans' !important;
}

.p-component {
    @media (max-width: 1500px) {
        font-size: 13px !important;
    }
}

h4 {
    font-weight: 500;
    font-size: 28px;

    @media (max-width: 1500px) {
        font-size: 22px !important;
    }
}

h6 {
    font-weight: 500;
    font-size: 18px;
    color: $heading;
    padding: 3px 0px 3px 0px;
    margin: 0;
}

.p-inputtext {
    border-radius: 13px;
    border-color: $border;
    padding: 10px;

    @media (max-width: 1500px) {
        padding: 4px 8px;
        font-size: 1em;
    }

    &::placeholder {
        color: #4e5257 !important;
    }
}

.p-checkbox-label {
    @media (max-width: 1500px) {
        font-size: 11.5px;
    }
}

.p-dropdown {
    border-radius: 14px;
    border-color: $border;
    padding: 1px;

    &::placeholder {
        color: #4e5257 !important;
    }
}

.p-treeselect {
    border-radius: 14px;
    border-color: $border;
    padding: 3px;

    &::placeholder {
        color: #4e5257 !important;
    }

}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #304FFD;
    background-color: #304FFD !important;
}

.p-treenode-content {
    box-shadow: none !important;
}

.p-treenode-content.p-highlight {
    .p-tree-toggler {
        color: #6c757d !important;
    }
    .p-checkbox-box {
        border-color: $accent !important;
        background: $accent !important;
    }
    background-color: transparent !important;
    color: #212529 !important;
}

.p-button {
    background-color: $accent;
    border-radius: 16px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-color: $accent !important;
    border-bottom: 2px solid #263fca !important;
    @media (max-width: 1500px) {
        padding: 6px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .p-button-label {
        color: $bg;
        font-weight: 400;
        font-size: 14px;
        @media (max-width: 1500px) {
            font-size: 12px !important;
        }
    }

    &:hover {
        background-color: #2843e1 !important;
    }
}

.p-inputgroup input {
    border-radius: 13px 0px 0px 13px !important;
}

.p-inputgroup button {
    border-radius: 0px 13px 13px 0px !important;
}

.p-button-danger {
    background-color: $red !important;
    border-color: $red !important;
    border-bottom: 2px solid $darkRed !important;

    &:hover {
        background-color: #ff635a !important;
    }
}

.p-button-secondary {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    border-bottom: 2px solid #454b50 !important;

    &:hover {
        background-color: #636a70 !important;
    }
}

.ITE-icon {
    height: 20px;
    font-size: 11px;
}

.login-button {
  background-color: #353C46 !important;
  border-bottom: none !important;

  .p-button-label {
      font-size: 1.3rem;
      font-weight: 500;
      margin-right: 30px;
  }

  &:hover {
        background-color: #272c33 !important;
  }
}

.table-header {
    background-color: #FFFFFF;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 30px 30px 0px 0px;
}

.table-footer {
    background-color: #FFFFFF;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 30px 30px;
    margin-bottom: 15px;

    .table-rows {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }

    .table-rows-quantity {
        padding: 12px;
        color: $bodyLight;
        font-size: 15px;
        @media (max-width: 1500px) {
            font-size: 13px;
        }
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.table-options-container {
    width: 100%;
    border-bottom: 2px solid $border;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .table-options {
        display: flex;
    }

    .table-preferences {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        @media (max-width: 768px) {
            justify-content: end;
            padding: 0px;
            margin-top: 12px;

            .preferences-button {
                font-size: 22px;
            }
        }
    }

    .filter-option {
        display: flex;
        align-items: center;
        padding-top: 14px;
        padding-bottom: 12px;
        margin-right: 14px;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &:hover {
            .option-label {
                color: #63676d;
            }
        }
    }

    .option-label {
        @media (max-width: 1500px) {
            font-size: 13px !important;
        }
        font-size: 15px;
        color: $bodyLight;
        transition: 0.33s ease;
    }

    .option-quantity {
        margin-left: 4px;
        background-color: $border;
        color: $bodyLight;
        font-weight: 600;
        padding: 3px;
        border-radius: 6px;
        font-size: 10px;
        padding-left: 5px;
        padding-right: 5px;
        @media (max-width: 1500px) {
            font-size: 9px !important;
        }
    }

    .active {
        .option-label {
            color: $heading;
        }

        border-bottom: 2px solid $accent;
    }

    @media (max-width: 768px) {
        display: block;
        border: none;
        margin-bottom: 23px;

        .table-options {
            display: block;
            width: 100%;
        }

        .filter-option {
            justify-content: space-between;
            margin: 0px;
        }
    }
}

.p-datatable {
    margin: 0px !important;
    padding: 0px !important;

    &.without-header-checkbox .p-datatable-thead tr > th .p-checkbox {
        visibility: hidden !important;
        height: 0px !important;
        width: 0px !important;
    }

    .p-datatable-thead {
        tr > th {
            .p-checkbox-box.p-highlight {
                border-color: #304FFD;
                background-color: #304FFD !important;
            }

            border-top: 0px;
            border-bottom: 1px solid $border;
            text-align: center;

            @media (max-width: 1500px) {
                padding: 15px 0px !important;
            }
        }

        .p-column-title {
            font-weight: 500;
            color: $bodyLight;
            text-transform: uppercase;
        }

        .pi-sort-amount-down {
            color: $bodyLight !important;
        }

        .pi-sort-amount-up-alt {
            color: $bodyLight !important;
        }
    }

    .p-datatable-tbody {
        .p-highlight {
            background-color: $bg !important;
            color: $heading !important;

            .p-checkbox-box.p-highlight {
                border-color: #304FFD;
                background-color: #304FFD !important;
            }
        }

        .p-row-expanded > td {
            padding: 0px !important;
            margin: 0px !important; 
        }

        .row-expansion {
            padding-top: 10px;
            padding-left: 2.5em;
            padding-right: 0em;
            padding-bottom: 1.25em;
            background-color: $bg !important;

            .row-expansion-label {
                color: $bodyLight;
                margin-right: 0.45em;
                font-size: 14px;
            }

            .row-expansion-value {
                color: $heading;
                font-size: 14px;
            }

            .consignado-color {
                color: #e56e19 !important;
            }

            .row-expansion-buttons {
                @media (max-width: 768px) {
                    display: block !important;
                    width: 100% !important;
                }
            }

            .row-expansion-button {
                flex: 1;
                align-self: flex-end;

                @media (max-width: 768px) {
                    width: 100% !important;
                    margin: 0px !important;
                    margin-top: 5px !important;
                    margin-bottom: 5px !important;
                }
            }

            @media (max-width: 768px) {
                padding: 10px;
            }
        }

        tr {
            color: $heading;
            font-size: 15px;
            @media (max-width: 1500px) {
                font-size: 11.7px !important;
            }

            td {
                border-bottom: 1px solid $border;
                text-align: center;
            }
        }
    }

    .nowrap-td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.p-menuitem-text {
    @media (max-width: 1500px) {
        font-size: 13px !important;
    }
}

.layout-topbar-menu {
    .p-splitbutton-defaultbutton {
        padding: 0px !important;
        cursor: initial;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 15px;
        }
    }

    .p-splitbutton-menubutton {
        padding: 0px !important;

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 12px;
        }
    }
}

.veiculo-situacao-D {
    font-size: 12px;
    color: #20A144;
    background-color: #ecf6ef;
    padding: 6px;
    border-radius: 7px;
}

.veiculo-situacao-B,
.veiculo-situacao-C,
.veiculo-situacao-F,
.veiculo-situacao-I,
.veiculo-situacao-R,
.veiculo-situacao-T,
.veiculo-situacao-V {
    font-size: 12px;
    color: #E56E19;
    background-color: #fff7f2;
    padding: 6px;
    border-radius: 7px;
}

.tipo-veiculo-n, .tipo-veiculo-u {
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;
}

.tipo-veiculo-n {
    color: #20A144;
    background-color: #ecf6ef;
}

.tipo-veiculo-u {
    color: $accent;
    background-color: #eef1ff;
}

.operacaoneg-Compra, .operacaoneg-Venda, .operacaoneg-Troca, .operacaoneg-Consignação {
    font-size: 12px;
    padding: 6px;
    border-radius: 7px;
}

.operacaoneg-Compra, .operacaoneg-Consignação {
   color: #20A144;
    background-color: #ecf6ef;
}

.operacaoneg-Troca {
    color: $darkOrange;
    background-color: #f8ede6; 
}

.operacaoneg-Venda {
    color: $accent;
    background-color: #eef1ff;
}

.secondary-info {
    color: $bodyLight;
}

.preferences-button {
    font-weight: bold;
    color: $bodyLight;
    font-size: 19px;
    cursor: pointer;
    transition: 0.33s ease;

    &:hover {
        color: #63676d;
    }
}

.p-paginator {
    margin-top: 0px !important;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: #eaedff !important;
        
        span {
            color: $accent !important;
            @media (max-width: 1500px) {
                font-size: 14px !important;
            }
        }
    }

    .p-disabled {
        color: $bodyLight !important;
    }

    .p-paginator-element {
        border-color: transparent !important;
        border-radius: 11px !important;
        margin-left: 3px !important;
        margin-right: 3px !important;
        color: $heading !important;
    }
    
    .p-paginator-pages {
        .p-highlight {
            background-color: $accent !important;
            color: white !important;
        }   
    }

    &-page {
        @media (max-width: 1500px) {
            font-size: 14px !important;
        }
    }

    @media (max-width: 768px) {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 12px !important;

    @media (max-width: 768px) {
        padding: 5px !important;
        max-width: 100% !important;
    }
}

.p-dialog {
    border: 1px solid transparent !important;
    border-radius: 20px 20px 0px 0px !important;

    .p-dialog-header-close {
        background-color: transparent !important;
    }

    .p-dialog-header {
        .p-dialog-title {
            font-size: 1.5rem !important;
            @media (max-width: 1500px) {
                font-size: 1.1rem !important;
            }
        }

        padding-left: 30px;
        border-radius: 23px 23px 0px 0px !important;
    }

    .p-dialog-content {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 3px !important;
        border-radius: 0px 0px 0px 0px !important;
    }

    .p-dialog-buttons {
        button {
            margin: 0 0.5rem 0 0;
            width: auto;
        }
        AppButton {
            margin: 0 0.5rem 0 0;
            width: auto; 
        }
        
        margin-top: 15px !important;
        text-align: right !important;
    }

    .p-dialog-footer {
        border-top: none !important;
        padding-top: 0px !important;
        border-radius: 0px 0px 23px 23px !important;
    }
}

.p-inputswitch-slider {
    border-radius: 50px !important;

    &::before {
        border-radius: 50px !important;
    }
}

.p-inputswitch.p-inputswitch-checked {
    .p-inputswitch-slider {
        background-color: $accent;
    }

    &:not(.p-disabeld):hover .p-inputswitch-slider {
        background-color: $accent;
    }
}

.p-fieldset {
    .p-fieldset-legend {
        font-weight: 300 !important;
    }
}

.p-accordion.unique {
    .p-accordion-tab {
        .p-accordion-header{
            .p-accordion-header-link {
                border-radius: 25px 25px 25px 25px !important;
            }

            &:not(.p-disabled).p-highlight{
                .p-accordion-header-link{
                    border-radius: 25px 25px 0px 0px !important;
                }
            }
        }
    }

    .p-accordion-content {
        border-radius: 0px 0px 25px 25px !important;
    }
}

.p-accordion.many {
    .p-accordion-tab {
        &:last-child {
            .p-accordion-header {
                .p-accordion-header-link {
                    border-bottom: 0px;
                    border-bottom-left-radius: 25px;
                    border-bottom-right-radius: 25px;
                }

                &:not(.p-disabled).p-highlight{
                    .p-accordion-header-link{
                        border-radius: 0px;
                    }
                }
            }

            .p-accordion-content {
                border-radius: 0px 0px 25px 25px;
            }
        }
    }
}

.p-accordion{

    .p-accordion-tab {
        &:first-child {
            margin-top: 2em !important;

            .p-accordion-header {
                .p-accordion-header-link {
                    border-radius: 25px 25px 0px 0px;
                    border-top: 0px !important;
                }
            }
        }

        

        .p-accordion-header {

            &:not(.p-disabled).p-highlight{
                .p-accordion-header-link{
                    border-bottom: 1px solid $border !important;
                }
            }

            .pi.pi-angle-double-down {
                color: $bodyLight !important;
                font-size: 15px !important;
                position: relative;
                padding-right: 9px !important;
            }

            .pi.pi-angle-double-up {
                color: $bodyLight !important;
                font-size: 15px !important;
                position: relative;
                padding-right: 9px !important;
            }
            
            .p-accordion-header-link {
                border: 0px !important;
                background-color: white !important;
                padding: 15px ;
                padding-left: 45px;
                padding-right: 50px;
                box-shadow: none !important;
                border-bottom: 1px solid $border !important;
                height: 62px !important;

                @media (max-width: 1500px) {
                    padding: 0px ;
                    padding-left: 25px;
                    padding-right: 30px;
                    height: 52px !important;
                }

                .p-accordion-toggle-icon {
                    visibility: hidden;
                    margin-right: 0px;
                }
            }
        }
    }

    .p-accordion-header-text {
        color: $heading;
        font-size: 20px;
        font-weight: 500 !important;
        flex: 1;

        @media (max-width: 1500px) {
            font-size: 17px !important;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .p-accordion-content {
        border: 0px;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        border-bottom: 1px solid $border !important;

    }

    @media (max-width: 768px) {
        .p-accordion-header {
            padding-left: 0px !important;
            padding-right: 0px !important;
        
            .p-accordion-header-link {
                padding-right: 20px !important;
                padding-left: 10px !important;
            }
        }

        .p-accordion-content {
            padding-top: 20px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }

}

.p-panel {
    .p-panel-header {
        border: 0px !important;
        background-color: white;
        padding: 15px !important;
        padding-left: 60px !important;
        padding-right: 50px !important;
        border-radius: 25px 25px 0px 0px;

        .pi {
            color: $bodyLight !important;
            font-size: 15px;
        }
    }

    .p-panel-title {
        color: $heading;
        font-size: 20px;
        font-weight: 500 !important;
    }

    .p-panel-content {
        border: 0px;
        border-top: 1px solid $border;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 0px 0px 25px 25px;
        padding-bottom: 20px !important;
    }

    @media (max-width: 768px) {
        .p-panel-header {
            padding-left: 25px !important;
            padding-right: 20px !important;
        }

        .p-panel-content {
            padding-top: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.p-scrolltop {
    border-radius: 100px;
    background-color: $accent !important;
    opacity: 0.75;

    &:hover {
        opacity: 0.9;
    }
}

.active-menuitem > a {
    background-color: $bg !important;

    i {
        color: $heading !important;
    }
}

.datalist {
    @media (min-width: 640px) {
        text-align: center;
    }

    th,
    td {
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

.expansion {
    padding: 25px 60px !important;
    &-button {
        width: 175px !important;
    }
}

.p-datatable .p-datatable-tbody>tr>td {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 768px) {
    .p-datatable.p-datatable-responsive .p-datatable-tbody > .p-datatable-row {
        &:first-child {
            border-top: 0px;
        }
        
        border-top: 1px solid rgb(202, 202, 202);
    }

    .p-datatable-row > td:first-child{
        margin-top: 12px;
    }

    .p-datatable-row > td:last-child{
        margin-bottom: 12px;
    }

    .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left !important;
        display: block;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
        margin-left: 5px;
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 35%;
        max-width: 35%;
        display: block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
        float: left;
        text-align: left;
    }

    .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
                
.p-dropdown .p-dropdown-trigger {
    width: 1.7rem;
}

.p-fieldset .p-fieldset-legend {
    padding: 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 1rem 0.2rem;
}

.custom-fieldset {
    .p-fieldset-content {
        display: flex;
        flex-wrap: wrap;
    }
}