@import "../variables";

.selection-datalist {
  table {
    table-layout: auto !important;
  }

  .p-datatable-scrollable-header {
    background-color: transparent !important;
  }

  &.filtro .p-datatable-thead {
    tr > th{
      &:first-of-type {
        border-top-left-radius: 0px !important;
      }

      &:last-of-type {
        border-top-right-radius: 0px !important;
      }
    }
  }

  .p-datatable-thead tr > th {
    height: 40px;

    &:first-of-type {
      border-top-left-radius: 30px;

      .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #304FFD;
        background-color: #304FFD !important;
      }
    }

    &:last-of-type {
      border-top-right-radius: 30px;
    }
  }

  .p-datatable-tbody {
    .p-highlight {
        background-color: white !important;
        color: $heading !important;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: #304FFD;
      background-color: #304FFD !important;
    }

    tr.row-processando .p-selection-column .p-checkbox {
      visibility: hidden;
    }

    tr {
      td {
        @media (max-width: 1500px) {
          font-size: 11.7px !important;
        }

        label {
          color: #3F434A;
        }
      }
    }

    tr.row-sem-xml {
      .p-selection-column .p-checkbox {
        visibility: hidden;
      }

      td {
        label {
          color: red;
        }

        p {
          color: red;
          position: absolute;
          margin-left: 5px;
          margin-top: -12px;
          
          @media (max-width: 1500px) {
            margin-top: -9px;
          }
        }
      }
    }

    tr.row-sem-xml-saida .p-selection-column .p-checkbox {
      visibility: hidden;
    }
  }
}

.confirm-button {
  height: 50px;
  margin-top: 7px;
  border-radius: 20px;
}

.rejeitar-veiculo-button {
  height: 23px !important;
  background-color: #FD7972;
  border: 1px solid #FD7972 !important;
  border-bottom: 2px solid #b1544f !important;
  
  &:hover {
    background-color: #f58983 !important;
  }
}

.info-selection {
  font-size: 1.2rem;

  @media (max-width: 1500px) {
    font-size: 1rem;
  }

  cursor: pointer;

  &.warning {
    font-size: 1.4rem !important;
  }
}