@import '../../layout/sass/mixins';

.container { 
  border-radius: 20px;
   height: 100% 
  }
.h-90po{
  height: 90%;
}

.h-350{
  height: 450px
}
.labelToken{
  padding-left: 10px;
}

.carentDisabled {
  caret-color: transparent;
}