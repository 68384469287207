.layout-profile {
    text-align: center;

    img {
        width: 56px;
        margin: 10px;
    }

    .layout-profile-link {
        // cursor: pointer;
        display: inline-block;
        margin-bottom: .75em;
        background-color: rgba($color: #000, $alpha: 0.0);
        border: none;
        outline: none;
        text-align: center;
        width: 100%;
        @include transition(color $transitionDuration);

        i {
            display: flex;
            font-size: 19px;
            align-items: flex-end;
            padding: 0 0 0 0.65em;
        }

        .company {
            font-size: large;
            @media (max-width: 1500px) {
                font-size: 1.2em !important;
            }
            font-weight: 700;
        }

    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        @include transition-property(max-height);
        @include transition-duration(0.4s);
        @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));
       
        &.layout-profile-expanded {
            max-height: 500px;
        }

        li {
            button {
                width: 100%;
                padding: 1em;
                border: 0 none;
                border-radius: 0;
                cursor: pointer;
                @include transition(color $transitionDuration);

                &:hover {
                    color: $menuitemHoverColor;
                }

                span {
                    margin-left: .25em;
                    vertical-align: middle;
                }

                i {
                    vertical-align: middle;
                }
            }
        }
    }
}

.layout-sidebar-light {
    .layout-profile {
        .layout-profile-link {
            color: $menuitemColor;
    
            &:hover {
                color: $menuitemHoverColor;
            }
        }

        ul {
            background-color: $menuitemActiveBgColor;

            li {
                button {
                    color: $menuitemColor;

                    &:hover {
                        color: $menuitemHoverColor;
                    }
                }
            }
        }
    }
}

.layout-sidebar-dark {
    .layout-profile {
        .layout-profile-link {
            color: $menuitemDarkColor;
    
            // &:hover {
            //     color: $menuitemDarkHoverColor;
            // }
        }

        ul {
            background-color: $menuitemDarkActiveBgColor;

            li {
                button {
                    color: $menuitemDarkColor;

                    &:hover {
                        color: $menuitemDarkHoverColor;
                    }
                }
            }
        }
    }
}