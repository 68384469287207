.p-tabmenu {
  overflow: auto !important;
  background-color: white !important;
  border-radius: 25px 25px 0px 0px !important;

  .p-menuitem-link {
    border-color: transparent !important;
    padding: 20px !important;
    padding-bottom: 25px !important;
    font-weight: 500 !important;
    color: $bodyDark !important;
    box-shadow: none !important;
  }

  .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: $accent !important;
    border-bottom: 2px solid $accent !important;
  }
}