.layout-topbar {
    position: absolute;
    height: 65px;
    color: #ffffff;
    z-index: 999;
    right: 0;
    @include clearfix();
    @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
    @include transition(left $transitionDuration);
    border-bottom: 1px solid #E8E9EB;


    .layout-topbar-icons {
        float: right;
        display: block;
        -moz-animation-duration: .5s;
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        button {
            position: relative;
            color: $topbarItemColor;
            margin-left: 20px;
            display: inline-block;
            text-decoration: none;
            @include transition(color $transitionDuration);
            overflow: visible;

            &:hover {
                color: $topbarItemHoverColor;
            }

            span {
                &.layout-topbar-icon {
                    font-size: 2em;
                }

                &.layout-topbar-item-text {
                    font-size: 20px;
                    display: none;
                }

                &.layout-topbar-badge {
                    position: absolute;
                    font-size: 10px;
                    right: -5px;
                    top: -5px;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    line-height: 16px;
                    color: $topbarItemBadgeColor;
                    background-color: $topbarItemBadgeBgColor;
                    @include border-radius(50%);
                }
            }
        }

        .layout-topbar-search {
            padding: 0;
            position: relative;
            display: inline-block;
            top: -4px;

            input {
                display: inline-block;
                border: 0 none;
                font-size: $fontSize;
                background: transparent;
                border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
                outline: 0 none;
                -webkit-box-shadow: none;
                box-shadow: none;
                color: $topbarSearchInputColor;
                width: 100px;
                padding: 1px 20px 1px 1px;
                margin: 0px;
                @include border-radius(2px);

                &::-webkit-input-placeholder {
                    color: $topbarSearchInputColor;
                    opacity: .7;
                    @include transition(opacity $transitionDuration);
                }

                &:-moz-placeholder {
                    color: $topbarSearchInputColor;
                    opacity: .7;
                    @include transition(opacity $transitionDuration);
                }

                &::-moz-placeholder {
                    color: $topbarSearchInputColor;
                    opacity: .7;
                    @include transition(opacity $transitionDuration);
                }

                &:-ms-input-placeholder {
                    color: $topbarSearchInputColor;
                    opacity: .7;
                    @include transition(opacity $transitionDuration);
                }
            }

            .layout-topbar-search-icon {
                font-size: 18px;
                position: absolute;
                top: -1px;
                right: 0px;
            }

            &:hover {
                input {
                    border-bottom-color: $topbarItemHoverColor;

                    &::-webkit-input-placeholder {
                        opacity: 1
                    }

                    &:-moz-placeholder {
                        opacity: 1
                    }

                    &::-moz-placeholder {
                        opacity: 1
                    }

                    &:-ms-input-placeholder {
                        opacity: 1
                    }
                }

                .layout-topbar-search-icon {
                    color: $topbarItemHoverColor;
                }
            }
        }
    }

    .layout-menu-button {
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        color: $topbarItemColor;
        @include transition(color $transitionDuration);

        span {
            box-shadow: none !important;
            font-size: 1.3em;
            font-weight: bold;
            padding-right: 40px;
            // padding: 20px;
        }

        &:hover {
            color: $topbarItemHoverColor;
        }
    }

    button {
        cursor: pointer;
    }

    .textCredito{
        color:black;
    }
    .button-despachante{
        width: 30%;
        @media (max-width: 768px) {
            width: 50%
        }

    }
    .mostrar-credito{
        width: 96%;
    }

    .layout-topbar-menu {
        height: 100%;
        float: right;
        align-items: center;
        display: flex;
        padding: 20px;
        width: 101%;

        button {
            margin-left: 15px;
        }

        .small-button {
            display: none;
        }

        .small-user-menu {
            display: none;
        }

        @media (max-width: 500px) {
            .small-button {
                display: inline-block;

                button {
                    font-weight: 900;
                    border: 1px;
                    border-radius: 7px;
                }
            }

            .big-button {
                display: none;
            }

            .big-user-menu {
                display: flex !important;
            }
    
        }
    }

    .user-menu {
        // width: 16.0rem !important;
        white-space: nowrap;
        button {
            color: #3F434A !important;
            margin-left: 0px;
            background-color: transparent;

            &:hover,
            &:active {
                background-color: transparent;
            }

            box-shadow: none;
            border: 0px !important;

            span {
                color: #3F434A !important;
            }
        }

        button.p-button-text-only {
            cursor: initial;
        }

        .p-button-text-only:focus,
        .p-button-icon-only:focus {
            border-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .p-button-label {
            display: flex;
            justify-content: end;
        }
    }
}