.registro {
  &.page {
    width: 100%;
    color: #cccccc;
    background: #FFFFFF;
  }

  .body {
    padding-bottom: 45px;
  }

  .text-left label,
  .text-right label {
    margin-top: 15px;
    border: 1.28571px solid #A5A5A5;
    border-radius: 6px;
    color: #464646;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 2px 13px;
    border: 1.28571px solid #D3D3D3;
    border-radius: 10.2857px;
  }

  .text-right label {
    cursor: pointer;
  }

  .text-left label {
    font-size: 1.0rem;
  }

  .text-left {
    margin-left: 20px;
  }

  .text-right {
    margin-right: 20px;
  }
  .logo-svg {
    width: 100%; 
    height: auto; 
    object-fit: contain; 
  }

  .form-container {
    width: 45%;
    margin: 0 auto; // alinha no centro
    height: 85%;
    margin-top: 25px;
    padding-top: 25px;
    box-shadow: 4px 5px 8px 3px rgba(0, 0, 0, 0.25);
    
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .form-container {
      width: 65%;
    }
    .text-left label,
    .text-right label {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    .form-container {
      width: 85%;
    }
    .text-left label,
    .text-right label {
      font-size: 0.7rem;
    }
  }
}
